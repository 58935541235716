.article {
	padding-top: 80px;
	padding-bottom: 40px;
	&-title {
		margin-bottom: 30px; }
	&-content {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: #616774;
		b, strong {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: #333333; }
		p {
			margin-bottom: 22px; }
		blockquote {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: #333333;
			position: relative;
			display: flex;
			padding-left: 20px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0px;
				bottom: 0;
				height: 100%;
				width: 6px;
				background-color: #00568F;
				display: flex; } }
		img {
			width: 460px;
			height: auto;
			object-fit: contain;
			margin-right: 30px;
			margin-bottom: 20px;
			float: left;
			@include r(575) {
				width: 100%; } }
		br.clear {
			clear: left; }
		table {
			margin-top: 35px;
			@extend .table; } } }
