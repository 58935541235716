.certificates {
	padding-top: 80px;
	padding-bottom: 40px;
	@include r(575) {
		padding-top: 20px;
		padding-bottom: 10px; }
	&-title {
		margin-bottom: 20px; }
	.certificate {
		position: relative;
		margin-bottom: 15px;

		&-link {
			position: relative;
			display: block;
			text-decoration: none;
			overflow: hidden;

			&:before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: rgba(255, 255, 255, 0);
				backdrop-filter: blur(0px);
				transition: background-color .2s ease-in-out, backdrop-filter .2s ease-in-out, transform .2s ease-in-out;
				z-index: 10;
				@extend %disable-text-decoration; }
			&:hover, &:focus {
				&:before {
					content: '';
					background-color: rgba(255, 255, 255, 0.3);
					backdrop-filter: blur(6px); }
				&:after {
					content: '+';
					font-size: 80px;
					font-weight: 300;
					color: #264ABA;
					position: absolute;
					top: 50%;
					right: 50%;
					transform: translate(50%, -50%);
					z-index: 40;
					opacity: 1;
					animation: rotate-custom 0.2s both, opacity 0.2s ease-in-out, font-weight 0.2s ease-in-out; }
				img {
					transform: scale(1.2); } } }
		&-img {
			display: block;
			height: auto;
			width: 100%;
			object-fit: contain;
			transform: scale(1);
			transition: transform .2s ease-in-out; }
		&-wrapper {
			margin-bottom: 30px; }
		&-text {
			font-size: 16px;
			font-family: Gilroy, sans-serif;
			color: #333333;
			display: flex;
			justify-content: space-around; }
		&-download {
			margin-left: 100px;
			@include r(575) {
				margin-left: 20px; }
			.icon {
				&:hover, &:focus {
					path {
						fill: #264ABA; } }
				path {
					fill: #333333; } } } } }

@keyframes rotate-custom {
	0% {
		transform: translate(50%, -50%) rotate(00deg) scale(2);
		opacity: 0.4;
		font-weight: 100; }
	50% {
		transform: translate(50%, -50%) rotate(45deg) scale(1.4);
		opacity: 0.6;
		font-weight: 200; }
	100% {
		transform: translate(50%, -50%) rotate(90deg) scale(1);
		opacity: 1;
		font-weight: 300; } }

.slider-mobile-certificates {
	> div {
		position: relative;
		display: block; }
	&-control {
		display: none;
		justify-content: center;
		@include r(575) {
			display: flex; }
		.icon {
			path {
				fill: #989EAB;
				stroke: #989EAB; }
			circle {
				fill: #ffffff;
				stroke: #989EAB; }
			&:focus, &:hover {
				cursor: pointer;
				path {
					fill: #264ABA;
					stroke: #264ABA; }
				circle {
					fill: #ffffff;
					stroke: #264ABA; } } } }
	&__prev {
		margin-right: 10px; } }
