.post {
	@extend %box-shadow;
	width: 100%;
	display: flex;
	margin-bottom: 30px;
	@include r(575) {
		flex-direction: column; }

	&-img {
		display: block;
		min-width: 350px;
		height: auto;
		object-fit: cover;
		object-position: center;
		@include r(575) {
			min-width: auto; } }

	&-info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 100%; }

	&-desc {
		background-color: #ffffff;
		padding-top: 20px;
		padding-left: 50px;
		padding-bottom: 20px;
		@include r(575) {
			padding: 10px; } }

	&-date {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 14px;
		color: #989EAB;
		margin-bottom: 15px; }

	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 20px;
		color: #333333;
		margin-bottom: 20px; }

	&-text {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 16px;
		color: #616774;
		margin-bottom: 4px;
		max-width: 570px; }

	&-link {
		max-width: 220px;
		@extend %button;
		background-color: transparent;
		color: #264ABA;
		padding: 0;
		width: 190px;
		.icon {
			path {
				fill: #264ABA; } } }

	&:focus, &:hover {
		.post-title {
			color: #264ABA; } } }
