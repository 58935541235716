.our-clients {
	padding-top: 80px;
	padding-bottom: 40px;
	@include r(767) {
		padding-top: 40px;
		padding-bottom: 30px; }
	@include r(575) {
		padding-top: 20px;
		padding-bottom: 20px; }
	.nav-item {
		margin-right: 80px;
		&:last-child {
			margin-right: 0; }
		@include r(991) {
			margin-right: 40px; }
		@include r(767) {
			margin-right: 30px; }
		@include r(575) {
			margin-right: 0; } }
	.nav-pills {
		margin-bottom: 55px;
		@include r(767) {
			justify-content: space-around; }
		@include r(575) {
			margin-bottom: 20px;
			justify-content: space-around; } }
	.nav-link {
		font-family: Gilroy, sans-serif;
		font-weight: 800;
		font-size: 40px;
		background-color: #ffffff;
		color: #616774;
		border-bottom: 1px solid #ffffff;
		padding-left: 0;
		padding-right: 0;
		@include r(767) {
			font-size: 30px; }
		@include r(575) {
			font-size: 23px; }
		&.active {
			background-color: #ffffff;
			color: #333333;
			border-radius: 0;
			border-bottom: 1px solid #333333; } }

	.tab-pane {
		.link {
			display: block;
			width: 100%;
			height: 100%;
			margin-bottom: 20px;
			&:hover, &:focus {
				filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.09)); }
			&-img {
				display: block;
				width: 100%;
				height: auto; } } } }

.slider-mobile-clients, .slider-mobile-objects {
	> div {
		position: relative;
		display: block; }
	&-control {
		display: none;
		justify-content: center;
		@include r(575) {
			display: flex; }
		.icon {
			path {
				fill: #989EAB;
				stroke: #989EAB; }
			circle {
				fill: #ffffff;
				stroke: #989EAB; }
			&:focus, &:hover {
				cursor: pointer;
				path {
					fill: #264ABA;
					stroke: #264ABA; }
				circle {
					fill: #ffffff;
					stroke: #264ABA; } } } }
	&__prev {
		margin-right: 10px; } }

.tab-content > .tab-pane,
.pill-content > .pill-pane {
    display: block;
    height: 0;
    overflow: hidden; }

.tab-content > .active,
.pill-content > .active {
    height: auto; }
