.hero {
	position: relative;
	padding-top: 110px;
	padding-bottom: 55px;
	@include r(991) {
		padding-top: 60px;
		padding-bottom: 25px; }
	@include r(767) {
		padding-top: 30px;
		padding-bottom: 25px; }
	@include r(575) {
		padding-top: 0;
		padding-bottom: 15px; }
	&.bg-image {
		overflow: hidden;
		position: relative;
		background-image: url("../img/hero/hero_index.jpg");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: center center;
		background-size: cover;
		@include r(575) {
			background: none; }
		&:before {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			background-image: url("../img/hero/hero_index-2.png");
			background-repeat: no-repeat;
			background-attachment: scroll;
			background-position: center center;
			background-size: contain;
			height: 710px;
			left: 20%;
			@include r(1199) {
				top: 0;
				height: 100%;
				opacity: 0.6; }
			@include r(991) {
				background-size: cover;
				top: 0;
				height: 100%;
				left: 0;
				opacity: 0.6; }
			@include r(767) {
				background-size: cover; }
			@include r(575) {
				background: none; } } }

	.bg-image-mobile {
		@include r(575) {
			position: relative;
			padding-top: 90px;
			padding-bottom: 70px;
			margin-bottom: 15px;
			&:before {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: -15px;
				left: -15px;
				background-image: url("../img/hero/hero_index.jpg");
				background-repeat: no-repeat;
				background-attachment: scroll;
				background-position: center center;
				background-size: cover;
				z-index: -10; }
			&:after {
				content: '';
				position: absolute;
				top: 0;
				bottom: 0;
				right: -75px;
				left: 50%;
				background-image: url("../img/hero/hero_index-2.png");
				background-repeat: no-repeat;
				background-attachment: scroll;
				background-position: right bottom;
				background-size: contain;
				z-index: -5;
				opacity: 0.5; } } }

	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 800;
		line-height: 50px;
		color: #333333;
		margin-bottom: 10px;
		@include r(767) {
			font-size: 35px; }
		@include r(575) {
			font-size: 23px;
			line-height: 26px;
			margin-bottom: 2px; }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 32px;
			color: #264ABA;
			line-height: 50px;
			margin-bottom: 120px;
			@include r(575) {
				margin-bottom: 25px;
				font-size: 14px;
				line-height: 20px; } } }

	&__services {
		display: flex;
		flex-wrap: wrap;
		@include r(575) {
			flex-direction: column; }
		.service {
			display: flex;
			margin-right: 40px;
			margin-bottom: 40px;
			min-width: 200px;
			align-items: center;
			@include r(575) {
				margin-right: 0;
				margin-bottom: 15px; }
			&-text {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 18px;
				color: #000000;
				.high {
					font-family: Gilroy, sans-serif;
					font-weight: 600;
					font-size: 18px;
					color: #000000;
					@include r(575) {
						font-weight: 400; } } } }
		.icon {
			margin-right: 25px;
			path {
				fill: #264ABA; } } } }

.hero-modal_button {
	@extend %button;
	margin: 0 auto;
	width: 260px;
	font-size: 12px;
	height: 35px;
	display: none;
	@include r(575) {
		display: block; } }
