.breadcrumb {
	background-color: transparent;
	padding-top: 20px;
	padding-bottom: 0;
	padding-left: 0;
	padding-right: 0;
	margin: 0; }

.breadcrumb-item {
	font-family: Gilroy, sans-serif;
	font-weight: 400;
	font-size: 14px;
	color: #989EAB;
	&:after {
		font-family: Gilroy,sans-serif;
		font-weight: 400;
		font-size: 14px;
		color: #989eab;
		content: "-";
		display: inline-block;
		padding-left: .5rem;
		padding-right: .5rem; }
	&:last-child {
		&:after {
			content: none; } } }

.breadcrumb-item + .breadcrumb-item {
	&:before {
		content: none; } }
.breadcrumb-item+.breadcrumb-item {
    padding-left: 0; }

.breadcrumb-link {
	font-family: Gilroy, sans-serif;
	font-weight: 400;
	color: #989EAB;
	@extend %disable-text-decoration;
	border-bottom: 1px solid transparent;
	&:hover, &:focus {
		color: #989EAB;
		border-bottom: 1px solid #989EAB; } }




.breadcrumb-wrapper {
	background-color: #ffffff;
	+ section {
		padding-top: 15px; } }

.breadcrumb-catalog {
	background-color: #F3F4F6; }

.breadcrumb-about {
	margin-bottom: 10px;
	.breadcrumb-item {
		color: #ffffff;
		&:after {
			color: #ffffff; } }
	.breadcrumb-link {
		color: #ffffff;
		@extend %disable-text-decoration;
		border-bottom: 1px solid transparent;
		&:hover, &:focus {
			color: #ffffff;
			border-bottom: 1px solid #ffffff; } } }

