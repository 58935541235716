@import "../fonts/Gilroy/project.css";
@import "../fonts/GothamPro/project.css";


body {
	font-family: Gilroy, sans-serif;
	font-weight: 400; }

.container {
	position: relative;
	max-width: 1176px; }

.section-title {
	font-family: Gilroy, sans-serif;
	font-weight: 800;
	font-size: 40px;
	text-transform: uppercase;
	@include r(991) {
		font-size: 30px; }
	@include r(767) {
		font-size: 24px; }
	@include r(575) {
		word-break: break-word;
		font-size: 20px; } }


.row.equal {
	display: flex;
	flex-wrap: wrap; }

.equal > div {
	@include r(575) {
		margin-bottom: 20px; } }

%button {
	height: 46px;
	font-size: 16px;
	font-family: Gilroy, sans-serif;
	font-weight: 500;
	background-color: #264ABA;
	outline: 0;
	border-radius: 0;
	border: 0;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	@extend %disable-text-decoration;

	.icon {
		width: 34px;
		margin-left: 0;
		margin-right: 14px;

		path {
			fill: #ffffff; } }

	&:hover, &:focus {
		color: #fff;
		background-color: #0069d9; }

	&.btn-link {
		&:hover, &:focus {
			color: #0069d9;
			background-color: transparent;
			font-weight: 500; } }

	&.btn-danger {
		&:hover, &:focus {
			background-color: #dc3545; } } }

%button-light {
	height: 38px;
	font-size: 14px;
	font-family: Gilroy, sans-serif;
	font-weight: 500;
	color: #333333;
	background-color: #D1DCFF;
	outline: 0;
	border-radius: 0;
	border: 0;
	box-shadow: none;
	display: flex;
	align-items: center;
	justify-content: center;

	.icon {
		width: 34px;
		margin-right: 0;
		margin-left: 12px;

		path {
			fill: #333333; } }

	&:hover, &:focus {
		color: #ffffff;
		background-color: #0069d9;

		.icon {
			path {
				fill: #ffffff; } } } }

%box-shadow {
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09); }

%disable-text-decoration {
	text-decoration: none;

	&:hover, &:focus {
		text-decoration: none; } }

.abs-right {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 1;

	> .row {
		height: 100%; }

	@include r(575) {
		width: 100%;
		height: 100%;
		left: 0; } }

.abs-right-content {
	padding-right: 0;
	@include r(575) {
		padding-left: 0; } }


