.select {
	padding-top: 80px;
	padding-bottom: 80px;
	@include r(991) {
		padding-top: 60px;
		padding-bottom: 50px; }
	@include r(767) {
		padding-top: 40px;
		padding-bottom: 30px; }
	@include r(575) {
		padding-top: 20px;
		padding-bottom: 20px; }
	&.bg-image {
		background-image: url("../img/hero/hero-1.jpg");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: center bottom;
		background-size: cover; }
	&-title {
		margin-bottom: 60px;
		@include r(575) {
			margin-bottom: 20px; } }
	&-box {
		border: 3px solid #264ABA;
		padding: 110px 0 120px 35px;
		width: 390px;
		height: 360px;
		@include r(991) {
			padding-top: 60px;
			width: 290px;
			height: 260px; }
		@include r(767) {
			margin-bottom: 10px; }
		@include r(575) {
			width: 250px;
			height: 260px;
			padding: 40px 0 60px 15px;
			margin-bottom: 20px; }
		&-line {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 120px;
			line-height: 100px;
			color: #264ABA; }
		&-subline {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 20px;
			color: #264ABA; } }

	&-desc {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: #333333;
		margin-bottom: 25px;
		text-align: justify; }
	.choice {
		margin-bottom: 40px;
		@include r(575) {
			margin-bottom: 15px; }
		&-icon {
			text-align: center; }
		.icon {
			@include r(575) {
				width: 40px;
				height: auto; }
			path {
				fill: #264ABA;
				stroke: #264ABA; } }
		&-text {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			color: #333333;
			font-size: 20px;
			.lower {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 18px; }
			@include r(575) {
				font-size: 18px;
				.lower {
					font-size: 16px; } } } } }
