.blog {
	padding-top: 80px;
	padding-bottom: 20px;
	@include r(575) {
		padding-top: 20px; }

	.nav-item {
		margin-right: 40px;
		@include r(575) {
			margin-right: 10px; } }

	.nav-pills {
		margin-bottom: 40px;
		@include r(575) {
			display: flex;
			justify-content: space-around;
			align-items: center; } }
	.nav-link {
		font-family: Gilroy, sans-serif;
		font-weight: 800;
		font-size: 40px;
		background-color: #ffffff;
		color: #616774;
		border-bottom: 1px solid #ffffff;
		padding-left: 0;
		padding-right: 0;
		@include r(575) {
			font-size: 20px; }
		&.active {
			background-color: #ffffff;
			color: #333333;
			border-radius: 0;
			border-bottom: 1px solid #333333; } }

	.tab-pane {} }

