.fast-order__form {
	.custom-checkbox .custom-control-input:checked~.custom-control-label {
		&:after {
			background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23333333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); } }
	.custom-control-label {
		&:before {
			color: #A2AABC;
			background-color: transparent;
			border-color: #A2AABC;
			box-shadow: none; } }

	.custom-control-input:checked~.custom-control-label::before {
		color: #A2AABC;
		border-color: #A2AABC;
		background-color: transparent;
		box-shadow: none; }

	&-wrapper {
		padding: 30px;
		background-color: #ffffff;
		box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.13); }
	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 600;
		color: #333333;
		font-size: 22px;
		margin-bottom: 15px; }

	&-phone, &-email, &-message {
		background-color: #F2F2F2;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 16px;
		color: #333333;
		margin-bottom: 15px;
		border: 1px solid #ffffff;
		box-shadow: none;
		outline: 0;
		width: 100%;
		&::placeholder {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #A2AABC; }
		&:hover, &:focus, &:active {
			border: 1px solid #DADADA; } }
	&-message {
		resize: none;
		&-label {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			color: #333333;
			font-size: 18px;
			margin-bottom: 5px; } }
	&-submit {
		width: 100%;
		@extend %button;
		margin-bottom: 15px; }

	&-terms-label {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 12px;
		color: #6A6A6A;
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 12px;
			color: #A2AABC;
			@extend %disable-text-decoration;
			border-bottom: 1px solid #ffffff;
			&:hover, &:focus {
				color: #A2AABC;
				border-bottom: 1px solid #A2AABC; } } } }
