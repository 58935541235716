%pagination-button {
	.page-link {
		border-radius: 40px;
		background-color: #ffffff;
		color: #333333;
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 18px;
		border: 1px solid #989EAB;
		width: 40px;
		height: 40px;
		margin-right: 5px;
		text-align: center;
		.icon {
			path {
				fill: #333333; } }
		&:hover, &:focus {
			background-color: #264ABA;
			color: #ffffff;
			.icon {
				path {
					fill: #ffffff; } } } } }

.pagination {
	padding: 40px 0;
	.page-item {
		@extend %pagination-button;
		&:first-child {
			@extend %pagination-button; }
		&:last-child {
			@extend %pagination-button;
			margin-right: 0px; }
		&.active {
			.page-link {
				border: 2px solid #264ABA; } }
		&.disabled {
			.page-link {
				color: #989EAB;
				.icon {
					path {
						fill: #989EAB; } } } } } }

