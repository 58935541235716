.not_found {
	overflow: hidden;
	.icon {
		position: absolute;
		left: 50%;
		top: 90px;
		transform: translateX(-30%);
		@include r(575) {
			top: 40px;
			width: auto;
			height: 350px; } }
	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 30px;
		margin-bottom: 30px;
		@include r(575) {
			font-size: 20px; } }
	&-link {
		@extend %button;
		width: 223px; }
	.control {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-top: 400px;
		padding-bottom: 120px;
		@include r(575) {
			padding-top: 250px;
			padding-bottom: 40px; } } }



