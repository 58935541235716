.projects {
	padding-top: 80px;
	padding-bottom: 70px;
	@include r(767) {
		padding-top: 40px;
		padding-bottom: 30px; }
	@include r(575) {
		padding-top: 20px;
		padding-bottom: 20px; }
	&-title {
		margin-right: 95px;
		@include r(575) {
			margin-right: 0; }
		&-wrapper {
			display: flex;
			align-items: center;
			margin-bottom: 60px;
			@include r(575) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 20px; }
			@include r(991) {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				margin-bottom: 20px; } }
		&-link {
			@extend %disable-text-decoration;
			&-text {
				font-family: Gilroy, sans-serif;
				font-weight: 500;
				font-size: 20px;
				color: #264ABA;
				border-bottom: 1px dashed #264ABA; }
			&:hover, &:focus {
				.projects-title-link-text {
					border-bottom: 1px solid #264ABA; } }
			.icon {
				margin-right: 13px;
				path {
					fill: #264ABA; } } } }

	.project {
		position: relative;
		overflow: hidden;
		@include r(575) {
			margin-bottom: 20px; }
		&-info {
			display: flex;
			flex-direction: column;
			justify-content: center;
			height: 100%;
			@include r(575) {
				justify-content: flex-start; } }
		&-desc {
			margin-bottom: 15px; }
		&-place {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #616774;
			margin-bottom: 10px; }
		&-name {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 30px;
			margin-bottom: 12px; }
		&-work {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 18px;
			color: #616774; }
		&-img {
			width: auto;
			height: 460px;
			object-fit: contain;
			display: block;
			&-wrapper {
				vertical-align: middle;
				box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.25); }
			@include r(575) {
				height: 100%; }
			@include r(991) {
				height: 360px; }
			@include r(1199) {
				height: 400px; } }
		&-details {
			display: flex;
			padding-top: 15px;
			padding-left: 0px;
			padding-right: 0px;
			padding-bottom: 15px;
			background-color: #ffffff;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 15px;
			@include r(767) {
				background-color: transparent; }
			&-col {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				flex-grow: 1;
				&-title {
					font-family: Gilroy, sans-serif;
					font-weight: 400;
					font-size: 16px;
					color: #616774;
					margin-bottom: 8px; }
				&-desc {
					font-family: Gilroy, sans-serif;
					font-weight: 600;
					font-size: 20px;
					color: #333333; } } }
		&-link {
			@extend %button;
			width: 212px;
			@include r(575) {
				width: 100%; } }
		.vertical {
			margin: 0 10px; } }

	.projects-slider {
		&-slider_number {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 26px;
			color: #989EAB;
			width: 100px; }
		&-control {
			display: flex;
			.icon {
				path {
					fill: #989EAB;
					stroke: #989EAB; }
				circle {
					fill: #ffffff;
					stroke: #989EAB; }
				&:focus, &:hover {
					cursor: pointer;
					path {
						fill: #264ABA;
						stroke: #264ABA; }
					circle {
						fill: #ffffff;
						stroke: #264ABA; } } }
			&-wrapper {
				margin-top: 20px;
				margin-right: 60px;
				display: flex;
				justify-content: center;
				align-items: center;
				.projects-slider-slider_number {
					margin-right: 60px; }
				@include r(575) {
					display: none; }
				&-mobile {
					position: relative;
					z-index: 10;
					display: none;
					@include r(575) {
						display: flex;
						justify-content: space-around; } } } }
		&__prev {
			margin-right: 10px; } }

	.projects-info-slider {
		margin-top: 65px;
		min-height: 500px;
		position: relative;
		z-index: 100;
		@include r(575) {
			margin-top: 0;
			margin-bottom: 20px;
			min-height: auto; } }

	.projects-img-slider {
		.slick-list {
			padding: 0 20% 0 0;
			@include r(575) {
				padding: 0; } }
		.slick-slide {
			@include r(767) {
				filter: blur(3px);
				opacity: 0.2; }
			.project-img-wrapper {
				filter: blur(3px);
				transition: transform 0.2s ease-in-out;
				transform: scale(0.9); } }
		.slide-current {
			.project-img-wrapper {
				filter: blur(0px);
				box-shadow: none;
				transform: scale(1);
				transition: transform 0.2s ease-in-out; }
			+ div {
				filter: blur(3px);
				&:before {
					content: '';
					position: absolute;
					top: 0;
					bottom: 0;
					left: 0;
					width: 650px;
					background-color: rgba(#ffffff, .2);
					background: linear-gradient(0deg, rgba(255, 255, 255, 0.52), rgba(255, 255, 255, 0.52));
					filter: blur(3px);
					z-index: 100;
					@include r(575) {
						left: -15px;
						right: -15px;
						top: 0;
						bottom: 0;
						width: 100%; } } } } } }



.container-wrapper {
	@include r(1200) {
		position: relative; } }
