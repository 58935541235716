.contacts {
	padding-bottom: 60px;
	@include r(575) {
		padding-bottom: 20px; }
	&-title {
		margin-bottom: 65px;
		@include r(575) {
			margin-bottom: 25px; } }
	&-col {
		margin-bottom: 40px;
		margin-left: 40px;
		@include r(575) {
			margin-left: 0; } }
	&-content {
		margin-left: 25px; }
	.working-hours {
		font-size: 20px; }
	.list-unstyled {
		color: #333333;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 16px;
		&-item {
			margin-bottom: 10px; } }
	.high {
		color: #333333;
		font-weight: 700; }
	.lower {
		color: #333333;
		font-weight: 400; }
	&-subtitle {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 25px;
		color: #333333;
		margin-bottom: 20px;
		position: relative;
		padding-left: 25px;
		display: flex;
		&:before {
			content: '';
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			height: 100%;
			border-left: 3px solid #264ABA; } }

	&-button {
		width: 207px;
		@extend %button-light;
		.icon {
			width: 16px;
			height: 16px; } }
	.phone {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid transparent;
		@extend %disable-text-decoration;
		&:focus, &:hover {
			color: #333333;
			border-bottom: 1px solid #333333; } }
	.email {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #333333;
		@extend %disable-text-decoration;
		&:focus, &:hover {
			color: #333333;
			border-bottom: 1px dashed #333333; } } }
