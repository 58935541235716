.mm-ocd {
	top: 75px;
	background-color: #ffffff;
	color: #333333;
	--mm-spn-item-height: 60px;
	--mm-ocd-width: 100%;
	--mm-ocd-max-width: 100%; }

.mm-ocd--open, .mm-spn.mm-spn--light {
    background: #ffffff; }


.mm-spn li.active {
	border-left: 2px solid #00568F;
	background-color: #F1F1F1; }

.mm-spn li {
	font-family: Gilroy, sans-serif;
	font-weight: 700;
	font-size: 20px;
	color: #333333;
	&:before {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 20px;
		color: #333333;
		opacity: 1; }
	&:after {
		margin-left: 0;
		border-top: 1px solid #F1F1F1;
		opacity: 1; } }

.mm-spn.mm-spn--navbar ul {
	&:before {
		border-top: 1px solid #F1F1F1;
		opacity: 1; } }

.mm-spn a:not(:last-child) {
	&:after {
    	content: none; } }

.mm-spn.mm-spn--navbar {
	&:before {
		right: 20px;
		opacity: 1;
		left: auto; } }


.mm-spn.mm-spn--navbar {
	&:after {
		opacity: 1;
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 20px;
		color: #333333; } }

#my-menu {
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-end;
	align-items: center;
	.menu-item + .link-wrapper {
		margin-top: 250px; }
	.link-wrapper {
		z-index: 100;
		display: none;
		&:before {
			content: none; }
		&:after {
			content: none; }
		@include r(767) {
			display: block; } }
	.modal_button-wrapper {
		border-top: 1px solid #F1F1F1;
		padding: 37px 27px; }
	.my-menu-modal_button {
		width: 100%;
		display: block;
		height: auto;
		@extend %button;
		font-size: 14px;
		min-height: 43px; }
	.my-menu-phone-link, .my-menu-email-link {
		@extend %disable-text-decoration;
		width: 280px;
		.icon {
			margin-right: 20px;
			path {
				fill: #DCDFE5; } } }
	.my-menu-phone-link {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 22px;
		color: #333333;
		display: flex;
		align-items: center;
		span {
			padding: 0;
			margin: 0;
			border-bottom: 1px solid transparent;
			&:hover, &:focus {
				border-bottom: 1px solid #333333; } } }
	.my-menu-email-link {
		font-family: Gilroy, sans-serif;
		font-weight: 600;
		font-size: 18px;
		color: #264ABA;
		display: flex;
		align-items: center;
		span {
			padding: 0;
			margin: 0;
			border-bottom: 1px solid rgba(0,86,143,.2);
			&:hover, &:focus {
				border-bottom: 1px dashed rgba(0,86,143,.2); } } } }

.mm-spn.mm-spn--navbar.mm-spn--main {
	&:after {
		content: none; } }

.mm-spn ul {
	&:after {
		content: none; } }



.mm-spn.mm-spn--navbar.mm-spn--main ul {
	top: 0; }


.mm-slideout {
  z-index:auto {} }
