.rent {
	padding-bottom: 80px;
	@include r(767) {
		padding-bottom: 40px; }
	@include r(575) {
		padding-bottom: 20px; }
	&-title {
		margin-bottom: 15px; }
	&-desc {
		margin-bottom: 40px;
		color: #616774;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 18px;
		text-align: justify; }
	&__form {
		.form-group {
			margin-bottom: 18px; }
		&-row {
			> div {
				padding-left: 5px;
				padding-right: 5px; }
			@include r(767) {
				flex-direction: column-reverse; } }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			color: #ffffff;
			font-size: 25px;
			margin-bottom: 8px; }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #989EAB;
			font-size: 16px;
			margin-bottom: 27px;
			@include r(575) {
				text-align: justify; } }

		&-phone, &-email {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			background-color: #ffffff;
			color: #333333;
			font-size: 16px;
			box-shadow: none;
			border-radius: 3px;
			outline: none;
			border-color: transparent;
			height: 40px;

			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #989EAB;
				font-size: 16px; } }
		&-submit {
			@extend %button;
			width: 200px;
			display: block;
			margin: 0 0 0 auto;
			@include r(991) {
				width: 100%; }
			@include r(767) {
				display: none; } }

		&-submit-mobile {
			@extend %button;
			width: 100%;
			display: none;
			margin: 0 0 0 auto;
			@include r(767) {
				display: block; } }

		&-rent-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #A2AABC;
			font-size: 16px; }
		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 12px;
			color: #A2AABC;
			margin-bottom: 0px;
			@include r(575) {
				margin-bottom: 10px; }

			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 12px;
				color: #F3F3F3;
				@extend %disable-text-decoration;

				border-bottom: 1px solid transparent;
				&:hover, &:focus {
					color: #F3F3F3;
					border-bottom: 1px solid #A2AABC; } } }
		&-img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover; }
		&-container {
			position: relative; }
		&-wrapper {
			background-color: rgba(#333333, .95);
			padding: 50px;
			position: absolute;
			left: 0;
			bottom: 0;
			right: 0;
			width: 100%;
			@include r(575) {
				padding: 10px 20px;
				position: relative; } } } }
