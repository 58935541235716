.history {
	padding-top: 0;
	&-title {
		margin-bottom: 58px; }
	&-timeline {
		position: relative; }
	&-label {
		position: relative;
		padding-top: 20px;
		padding-bottom: 20px;
		padding-left: 70px;
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: center center;
		background-size: cover;
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 20px;
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
		&.bg-1 {
			background-image: url("../img/history/1.jpg"); }
		&.bg-2 {
			background-image: url("../img/history/2.jpg"); }
		&.bg-3 {
			background-image: url("../img/history/3.jpg"); }
		&.bg-4 {
			background-image: url("../img/history/4.jpg"); }
		&.bg-5 {
			background-image: url("../img/history/5.jpg"); }
		&.bg-6 {
			background-image: url("../img/history/6.jpg"); }
		&.bg-7 {
			background-image: url("../img/history/7.jpg"); }
		&.bg-8 {
			background-image: url("../img/history/8.jpg"); }
		&.bg-9 {
			background-image: url("../img/history/9.jpg"); }
		&.bg-10 {
			background-image: url("../img/history/10.jpg"); }
		&.bg-11 {
			background-image: url("../img/history/11.jpg"); }
		@include r(575) {
			flex-direction: column; }
		&-year {
			font-family: Gilroy, sans-serif;
			font-weight: 800;
			font-size: 70px;
			color: #264ABA;
			margin-right: 50px;
			&:before {
				content: '';
				position: absolute;
				left: 35px;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 10px;
				height: 10px;
				width: 10px;
				background-color: #989EAB;
				z-index: 100; }
			@include r(575) {
				&:before {
					top: 75px; } } }
		&:hover, &:focus {
			.history-label-year {
				&:before {
					background-color: #264ABA; } } }
		&-info {
			display: flex;
			flex-direction: column;
			&-title {
				font-family: Gilroy, sans-serif;
				font-weight: 600;
				font-size: 20px;
				color: #333333;
				margin-bottom: 15px; }
			&-desc {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 16px;
				color: #616774; } }
		&:before {
			content: '';
			position: absolute;
			left: 40px;
			width: 1px;
			height: 60%;
			top: -10%;
			background-color: #989EAB; }
		@include r(575) {
			&:before {
				top: 0%;
				bottom: auto;
				height: 75px; } }
		&:after {
			content: '';
			position: absolute;
			left: 40px;
			width: 1px;
			height: 60%;
			bottom: -10%;
			background-color: #989EAB; }
		@include r(575) {
			&:after {
				top: 75px;
				bottom: auto;
				height: 100%; } }
		&:first-child {
			&:before {
				content: none; } }
		&:last-child {
			&:after {
				content: none; } }
		&:hover, &:focus {
			.history-label-info {
				&-title {
					color: #264ABA; }
				&-desc {
					color: #989EAB; } } } } }

