.about {
	padding-top: 0px;
	.bg-image {
		background-image: url("../img/hero/hero-2.jpg");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: 75% center;
		background-size: cover;
		height: 520px;
		@include r(575) {
			height: auto;
			padding-bottom: 10px; } }
	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 800;
		font-size: 40px;
		margin-bottom: 23px;
		color: #ffffff; }
	&-subtitle {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 25px;
		margin-bottom: 20px;
		color: #ffffff; }
	&-desc {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 18px;
		max-width: 585px;
		color: #ffffff;
		@include r(575) {
			text-align: justify; } }
	&-production {
		box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
		background-color: #ffffff;
		padding: 50px 70px;
		margin-top: -115px;
		margin-bottom: 80px;
		@include r(575) {
			padding: 20px 10px;
			margin-top: 0; }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 25px;
			color: #333333;
			margin-bottom: 15px; }
		&-text {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 18px;
			margin-bottom: 30px;
			color: #616774; }
		.wrapper-1 {
			max-width: 820px; }
		.wrapper-2 {
			max-width: 570px; } } }
