.catalog_request {
	background-color: #F3F3F3;
	&__form {
		padding-top: 50px;
		margin-bottom: 50px;
		.custom-checkbox .custom-control-input:checked~.custom-control-label {
			&:after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23333333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); } }
		.custom-control-label {
			&:before {
				color: #A2AABC;
				background-color: transparent;
				border-color: #A2AABC;
				box-shadow: none; } }

		.custom-control-input:checked~.custom-control-label::before {
			color: #A2AABC;
			border-color: #A2AABC;
			background-color: transparent;
			box-shadow: none; }
		.form-group {
			margin-bottom: 0; }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 25px;
			color: #333333;
			margin-bottom: 20px; }


		&-phone, &-email, &-message {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			background-color: #ffffff;
			color: #333333;
			font-size: 16px;
			box-shadow: none;
			border-radius: 3px;
			margin-bottom: 12px;
			outline: none;
			height: 40px;
			border: 1px solid #ffffff;
			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #A2AABC;
				font-size: 16px; }
			&:focus, &:hover {
				border: 1px solid #A2AABC; } }
		&-message {
			height: 100%;
			resize: none; }

		&-submit {
			@extend %button;
			width: 212px;
			margin: 0 0 18px auto;
			@include r(575) {
				margin: 0 auto 18px 0;
				width: 100%; } }

		.custom-file {
			width: 192px;
			&:hover, &:focus {
				.catalog_request__form-upload-label {
					color: #ffffff;
					background-color: #0069d9; }
				.icon {
					path {
						fill: #ffffff; } } } }
		&-upload-label {
			@extend %button-light;
			margin: 0 0 18px 0;
			width: 192px;
			&:after {
				content: none; } }

		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #333333;
			margin-bottom: 0px;
			max-width: 400px;
			font-size: 12px;
			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #989EAB;
				@extend %disable-text-decoration;
				font-size: 12px;
				&:hover, &:focus {
					color: #989EAB;
					border-bottom: 1px solid #A2AABC; } } } }
	.bg-image {
		height: 100%;
		position: relative;
		background-image: url("../img/request.png");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: right center;
		background-size: contain;
		@include r(575) {
			background: none; } }

	.control {
		display: flex;
		@include r(575) {
			.custom-file {
				margin: 0 auto 10px auto; }
			flex-direction: column;
			justify-content: flex-start; } } }
