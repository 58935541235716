.logo {
	&-link {
		display: block;
		width: 212px;
		@extend %disable-text-decoration;
		@include r(575) {
			width: 165px; }
		@include r(991) {
			width: 185px; } }

	.icon {
		margin-bottom: 5px;
		@include r(575) {
			width: 165px; }
		@include r(991) {
			width: 185px; }
		path {
			fill: #004D7E; } }
	.slogan {
		font-family: Gotham Pro, sans-serif;
		font-weight: 500;
		font-size: 10px;
		color: #004E7E;
		@include r(575) {
			font-size: 8px; }
		@include r(991) {
			font-size: 9px; } } }
