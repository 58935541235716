// section -- _catalog-category
.catalog {
	&.catalog-category {
		background-color: #ffffff;
		padding-bottom: 30px;
		@include r(575) {
			padding-bottom: 10px; } }
	.catalog-category {
		&-filter {
			margin-bottom: 40px;
			padding-left: 0;
			list-style: none;
			.nav-link {
				border-radius: 0px;
				border-bottom: 1px dashed #333333;
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 20px;
				color: #333333;
				padding: 2px 0;
				@extend %disable-text-decoration;
				margin-right: 40px;
				margin-bottom: 20px;
				@include r(575) {
					margin-right: 20px;
					font-size: 18px; }
				&.active {
					padding: 2px 10px;
					color: #333333;
					border-radius: 5px;
					background-color: #D2D2D2;
					border-bottom: 0px dashed #333333; }
				&:hover, &:focus {
					border-bottom: 1px solid #333333; } } }

		&-cards {
			margin-bottom: 20px;
			@include r(575) {
				margin-bottom: 10px; }
			.card {
				margin-bottom: 30px;
				box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
				border-radius: 0;
				border: 0;
				&:hover, &:focus {
					.card {
						&-title {
							color: #264ABA; } } }
				&-img {
					border: 0;
					object-fit: contain; }
				&-body {
					padding: 30px 0px;
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center; }

				&-title {
					font-family: Gilroy, sans-serif;
					font-weight: 700;
					font-size: 30px;
					color: #333333;
					margin-bottom: 5px;
					@include r(991) {
						font-size: 26px; } }
				&-desc {
					font-family: Gilroy, sans-serif;
					font-weight: 400;
					font-size: 14px;
					color: #616774;
					margin-bottom: 20px; }
				&-info {
					text-align: left;
					margin-bottom: 25px;
					th {
						font-family: Gilroy, sans-serif;
						font-weight: 400;
						font-size: 16px;
						color: #616774;
						padding-right: 12px; }
					tr {
						font-family: Gilroy, sans-serif;
						font-weight: 400;
						font-size: 16px;
						color: #333333; } }
				&-link {
					width: 210px;
					@extend %button;
					@include r(575) {
						width: 100%; } } } }

		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 25px;
			color: #000000;
			margin-bottom: 20px; }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 18px;
			color: #616774;
			margin-bottom: 20px;
			text-align: justify; } } }

