.social {
	margin-bottom: 50px;
	&-link {
		@extend %disable-text-decoration;
		margin-right: 30px;
		&:last-child {
			margin-right: 0; }
		path {
			fill: #616774; }
		&:hover, &:focus {
			path {
				fill: #264ABA; } } } }
