// section -- _catalog

.catalog {
	background-color: #F3F4F6;
	position: relative;
	padding-top: 80px;
	padding-bottom: 125px;
	@include r(991) {
		padding-top: 80px;
		padding-bottom: 60px; }
	@include r(767) {
		padding-top: 40px;
		padding-bottom: 40px; }
	@include r(575) {
		padding-top: 20px;
		padding-bottom: 20px; }

	//&-breadcrumb
	//	padding-top: 20px
	//	background-color: #ffffff
	//&-card-item
	//	padding-top: 20px
	//	background-color: #ffffff
	//&-items
	//	background-color: #ffffff
	&-title {
		color: #333333;
		margin-bottom: 30px;
		&-link {
			color: #264ABA;
			font-size: 20px;
			@extend %disable-text-decoration;
			&-text {
				font-family: Gilroy, sans-serif;
				font-weight: 500;
				border-bottom: 0px dashed #264ABA; }
			&:hover, &:focus {
				&-text {
					border-bottom: 0px solid #264ABA; } }
			.icon {
				margin-right: 13px;
				path {
					fill: #264ABA; } } } }


	.category {
		margin-bottom: 30px;
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 30px;
			text-transform: uppercase;
			margin-bottom: 20px;
			color: #333333;
			@include r(575) {
				font-size: 14px; } } }

	.subcategory {
		overflow: hidden;
		background-color: #ffffff;
		position: relative;
		padding: 30px;
		display: flex;
		min-height: 240px;
		height: 100%;
		filter: drop-shadow(0px 10px 20px rgba(0, 0, 0, 0.09));
		transition: background-color .4s ease-in-out;

		&-desc {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%; }

		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 20px;
			max-width: 200px;
			margin-bottom: 10px; }


		&-filter {
			margin-bottom: 10px;
			&-link {
				font-family: Gotham Pro, sans-serif;
				font-weight: 400;
				color: #264ABA;
				border-bottom: 1px dashed #264ABA;
				font-size: 16px;
				@extend %disable-text-decoration; }
			&-item {
				margin-bottom: 5px; } }

		&-img {
			object-fit: contain;
			position: absolute;
			right: 5px;
			top: 50%;
			transform: translateY(-50%);
			z-index: -40;
			@include r(575) {
				opacity: 0.6; }
			@include r(991) {
				opacity: 0.6; }
			@include r(991) {
				opacity: 0.6; }
			@include r(1199) {
				opacity: 0.6; } }
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 500;
			@extend %disable-text-decoration;
			color: #000000;
			font-size: 16px;
			display: flex;
			align-items: center;
			animation: none;
			width: 160px;
			.icon {
				animation: none;
				margin-right: 10px;
				transition: transform 0.2s ease-in-out;
				path {
					fill: #000000; } } }

		&:hover, &:focus {
			background-color: #264ABA;
			.subcategory {
				&-title {
					color: #ffffff; }
				&-link {
					color: #ffffff;
					&:focus, &:hover {
						font-weight: 600;
						animation: pulse-opacity 4s infinite;
						.icon {
							animation: pulse-opacity 4s infinite;
							transform: rotate(45deg);
							path {
								fill: #ffffff; } } }
					.icon {
						path {
							fill: #ffffff; } } }
				&-filter {
					&-link {
						border-bottom: 1px dashed #ffffff;
						color: #ffffff;
						&:focus, &:hover {
							border-bottom: 1px solid #ffffff; } } } } } } }



@keyframes pulse-opacity {
  0% {
    opacity: 0.6; }
  70% {
  	opacity: 0.95; }
  100% {
  	opacity: 0.6; } }

