.project-process {
	padding-top: 35px;
	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 25px;
		color: #333333;
		margin-bottom: 15px; }
	&-desc {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		font-size: 18px;
		color: #333333;
		margin-bottom: 100px;
		@include r(575) {
			margin-bottom: 20px; } }
	&-img {
		float: right;
		@include r(575) {
			float: none; } }
	&-timeline {
		overflow: hidden;
		position: relative;
		padding: 0;
		margin: 0; }
	&-label {
		margin-left: 70px;
		margin-bottom: 165px;
		height: 359px;
		display: flex;
		justify-content: flex-start;
		align-items: center;

		&:last-child {
			margin-bottom: 65px; }
		@include r(767) {
			margin-bottom: 45px; }
		@include r(575) {
			height: auto;
			margin-left: 0;
			margin-bottom: 20px; }
		&-info {
			padding: 40px 68px;
			background-color: #ffffff;
			box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
			display: flex;
			flex-direction: column;
			position: relative;
			min-width: 548px;
			max-width: 548px;
			&:before {
				content: '';
				position: absolute;
				left: -40px;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 10px;
				height: 20px;
				width: 20px;
				background-color: #989EAB;
				z-index: 100; }
			@include r(575) {
				padding: 10px 10px;
				min-width: auto;
				max-width: none;
				&:before {
					content: none; } } }
		&:focus, &:hover {
			.project-process-label-info {
				&:before {
					background-color: #264ABA; } } }

		&-img-wrapper {
			margin-left: -45px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			@include r(575) {
				display: none; } }
		&-img {
			display: block;
			height: 100%;
			width: auto;
			object-fit: contain;
			@include r(767) {
				display: none; } }
		&-title {
			color: #333333;
			font-family: Gilroy, sans-serif;
			font-size: 20px;
			font-weight: 600;
			margin-bottom: 15px; }
		&-desc {
			color: #616774;
			font-family: Gilroy, sans-serif;
			font-size: 16px;
			font-weight: 400; }
		&:first-child {
			&:before {
				content: none; } }
		&:last-child {
			&:after {
				content: none; } }
		&:before {
			content: '';
			position: absolute;
			left: 40px;
			width: 1px;
			height: 60%;
			top: 12%;
			background-color: #989EAB;
			@include r(767) {
				top: 14%; } }
		&:after {
			content: '';
			position: absolute;
			left: 40px;
			width: 1px;
			height: 60%;
			bottom: 16%;
			background-color: #989EAB;
			@include r(767) {
				bottom: 19%; } }
		@include r(575) {
			&:before {
				content: none; }
			&:after {
				content: none; } }
		&-year {
			color: #264ABA;
			font-size: 70px;
			font-family: Gilroy, sans-serif;
			margin-right: 50px;
			&:before {
				content: '';
				position: absolute;
				left: 35px;
				top: 50%;
				transform: translateY(-50%);
				border-radius: 10px;
				height: 10px;
				width: 10px;
				background-color: #989EAB; } } } }

