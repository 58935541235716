.reviews {
	padding-top: 40px;
	padding-bottom: 80px;
	@include r(767) {
		padding-top: 30px;
		padding-bottom: 30px; }
	&-title {
		margin-bottom: 40px; }
	.slide-current {
		.review {
			transition: transform .2s ease-in-out;
			transform: scale(1);
			@include r(575) {
				transform: scale(0.9); } } }
	.review {
		transition: transform .2s ease-in-out;
		transform: scale(0.8);
		box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.25);
		padding: 50px 90px;
		width: 755px;
		max-width: 100vw;
		margin: 30px;
		@include r(575) {
			padding: 20px 10px;
			margin: 10px; }
		&-info {
			display: flex;
			align-items: center;
			margin-bottom: 30px;
			&-img {
				display: block;
				width: 100%;
				height: auto;
				max-width: 150px; } }
		&-text {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #333333;
			font-size: 16px;
			margin-bottom: 30px; }
		&-link {
			color: #264ABA;
			font-family: Gilroy, sans-serif;
			font-weight: 500;
			font-size: 18px;
			border-radius: 0;
			outline: 0;
			box-shadow: none;
			display: block;
			width: 170px;
			margin: 0 auto;
			border: 1px solid #264ABA;
			@extend %disable-text-decoration;
			&:hover, &:focus {
				background-color: #264ABA;
				color: #ffffff; } }
		.line-top {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 20px;
			color: #333333; }
		.line-bottom {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 18px;
			color: #616774; }
		&-client {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 20px;
			color: #333333;
			&-subline {
				color: #616774;
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 18px; } } }
	&-slider-info {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 26px;
		text-align: center;
		color: #D3D3D3; }

	&-slider-control {
		display: flex;
		justify-content: center;
		align-items: center;
		.reviews-slider__prev {
			margin-right: 10px; }
		.icon {
			path {
				fill: #989EAB;
				stroke: #989EAB; }
			circle {
				fill: #ffffff;
				stroke: #989EAB; }
			&:focus, &:hover {
				cursor: pointer;
				path {
					fill: #264ABA;
					stroke: #264ABA; }
				circle {
					fill: #ffffff;
					stroke: #264ABA; } } } } }
