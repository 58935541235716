.commercial_offer {
	margin-top: -70px;
	background: linear-gradient(180deg, rgba(255,255,255,0) 70px,  rgba(38,74,186,1) 70px, rgba(38,74,186,1) 100%);
	padding-top: 0;
	padding-bottom: 0;
	position: relative;
	@include r(767) {
		padding-top: 20px;
		padding-bottom: 20px;
		margin-top: 0;
		background-color: rgba(38,74,186,1); }
	.bg-image {
		padding-top: 145px;
		background-image: url("../img/manager.png");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: right bottom;
		background-size: contain;
		@include r(767) {
			background: none;
			padding-top: 0;
			opacity: 1; } }
	&__form {
		padding-top: 0px;
		margin-bottom: 75px;
		@include r(767) {
			margin-bottom: 35px; }
		@include r(575) {
			margin-bottom: 0; }
		.row {
			margin-bottom: 12px; }
		.row + .row {
			margin-bottom: 22px; }
		.custom-control-label {
			&:before {
				color: #A2AABC;
				background-color: transparent;
				border-color: #616774;
				box-shadow: none; } }
		.custom-control-input:checked~.custom-control-label::before {
			color: #A2AABC;
			border-color: #616774;
			background-color: transparent;
			box-shadow: none; }

		.form-group {
			margin-bottom: 12px; }
		&-title {
			text-transform: uppercase;
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 30px;
			color: #ffffff; }

		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #F3F3F3;
			margin-bottom: 27px;
			max-width: 500px; }

		&-name, &-phone, &-email, &-message {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			background-color: #ffffff;
			color: #333333;
			box-shadow: none;
			border-radius: 3px;
			margin-bottom: 0px;
			outline: none;
			border-color: transparent;
			height: 40px;
			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 16px;
				color: #989EAB; } }
		&-message {
			height: 145px;
			resize: none;
			&.form-control {
				height: 145px; } }
		&-submit {
			background-color: #FE1721;
			@extend %button;
			width: 212px;
			display: block;
			margin-left: auto;
			@include r(767) {
				margin: 0 auto 22px 0; }
			@include r(575) {
				width: 100%; } }

		&-rent {
			background-color: transparent; }
		&-rent-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #A2AABC;
			padding-left: 20px;
			margin-bottom: 10px;
			@include r(575) {
				margin-bottom: 20px; }
			&:before, &:after {
				width: 25px;
				height: 25px;
				top: 0px;
				left: -25px; } }

		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #A2AABC;
			margin-bottom: 0px;
			max-width: 400px;
			font-size: 12px;
			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #FFFFFF;
				@extend %disable-text-decoration;
				font-size: 12px;
				border-bottom: 1px solid #264ABA;
				&:hover, &:focus {
					color: #FFFFFF;
					border-bottom: 1px solid #A2AABC; } } } }

	&-manager {
		width: 100px;
		@include r(991) {
			display: none; }
		&-name {
			font-family: Gilroy, sans-serif;
			font-weight: 500;
			font-size: 20px;
			color: #FFFFFF; }
		&-position {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #A2AABC; } } }


.commercial_offer-light {
	.commercial_offer {
		background: linear-gradient(180deg, rgba(255,255,255,0) 70px,  rgba(#F4F4F4,1) 70px, rgba(#F4F4F4,1) 100%);
		@include r(767) {
			background-color: rgba(#F4F4F4,1); }
		&__form {
			.custom-checkbox .custom-control-input:checked~.custom-control-label {
				&:after {
					background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23333333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); } }

			&-title {
				color: #2A2B2D; }
			&-desc {
				color: #616774; }

			&-name, &-phone, &-email, &-message {
				background-color: #ffffff;
				color: #333333;
				&::placeholder {
					color: #989EAB; } }
			&-submit {
				background-color: #264ABA;
				&:hover, &:focus {
					color: #fff;
					background-color: #0069d9; } }

			&-rent-label {
				color: #A2AABC; }

			&-terms-label {
				color: #6A6A6A;
				&-link {
					color: #A2AABC;
					border-bottom: 1px solid #F4F4F4;
					&:hover, &:focus {
						color: #A2AABC;
						border-bottom: 1px solid #A2AABC; } } } }
		&-manager {
			&-name {
				color: #264ABA; }
			&-position {
				color: #616774; } } } }
