// section -- _catalog-card
.catalog {
	&.catalog-card {
		background-color: #ffffff;
		padding-bottom: 96px;
		@include r(575) {
			padding-bottom: 20px; } }
	.catalog-card {
		&-title {
			margin-right: 50px;
			margin-bottom: 0;
			&-wrapper {
				display: flex;
				align-items: flex-start;
				justify-content: space-between;
				margin-bottom: 30px;
				@include r(767) {
					justify-content: flex-start;
					flex-direction: column-reverse;
					.catalog-card-title {
						margin-right: 0; }
					.catalog-card-title-link {
						margin-bottom: 10px; } } }
			&-link {
				min-width: 250px;
				@extend %disable-text-decoration;
				&-text {
					font-family: Gilroy, sans-serif;
					font-weight: 500;
					font-size: 20px;
					color: #264ABA;
					border-bottom: 1px dashed #ffffff; }
				&:hover, &:focus {
					.catalog-card-title-link-text {
						border-bottom: 1px solid #264ABA; } }
				.icon {
					margin-right: 13px;
					path {
						fill: #264ABA; } } } } }

	.card {
		margin-bottom: 50px;
		border: 0;
		outline: 0;
		box-shadow: none;

		&-info {
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: center; }

		&-modal_button {
			width: 355px;
			@extend %button;
			@include r(767) {
				width: 100%;
				height: auto; } }

		&-info-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 16px;
			color: #333333;
			margin-bottom: 30px; }


		.card-feature-item {
			margin-bottom: 10px; }

		&-feature {
			display: block;
			margin: 0;
			padding: 0;
			margin-bottom: 40px;

			.feature li {
				display: block;
				letter-spacing: -1ex;
				margin: 5px 0; }

			.feature-left {
				margin-right: -50%;
				width: 49%;
				vertical-align: top;
				overflow: hidden;
				display: inline-block;
				letter-spacing: normal;
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 16px;
				color: #616774; }


			.feature-left:after {
				content: '';
				display: inline-block;
				width: 100%;
				margin: 0 -99% 0 3px;
				vertical-align: baseline;
				border-bottom: 1px dashed #888; }

			.feature-right {
				display: inline-block;
				letter-spacing: normal;
				padding-left: 3px;
				margin-left: 50%;
				width: 49%;
				vertical-align: bottom;
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 16px;
				color: #333333; } }


		&-slider-wrapper {
			position: relative; }

		&-slider-control {
			position: absolute;
			bottom: 22px;
			left: 20px;
			right: 20px;
			display: flex;
			justify-content: space-between;
			.icon {
				path {
					fill: #9B9DA3; }
				&:hover, &:focus {
					cursor: pointer;
					path {
						fill: #333333; } } } }

		&-img-slider {
			.slick-dots {
				z-index: 20;
				position: absolute;
				bottom: 22px;
				list-style: none;
				display: block;
				text-align: center;
				padding: 0;
				width: 50%;
				left: 50%;
				transform: translateX(-50%);
				li {
					position: relative;
					display: inline-block;
					cursor: pointer;
					button {
						display: block;
						padding: 3px;
						background: none;
						outline: none;
						line-height: 0px;
						font-size: 0px;
						color: transparent;
						cursor: pointer;
						border: 0;
						box-shadow: none;
						&:before {
							content: "";
							display: block;
							height: 10px;
							width: 10px;
							box-sizing: border-box;
							border-radius: 50%;
							background: #F3F3F3;
							transition: all 0.1s ease; }
						&:hover {
							&:before {
							   background: #00568F; } } }

					&.slick-active button:before {
						background: #00568F; } } } }


		&-advantage {
			display: flex;
			justify-content: space-between;
			margin-bottom: 80px;
			@include r(575) {
				margin-bottom: 20px;
				flex-direction: column;
				justify-content: flex-start; }
			.icon {
				margin-bottom: 10px;
				@include r(575) {
					min-width: 100px; } }

			&-item {
				max-width: 180px;
				text-align: center;
				@include r(575) {
					max-width: 100%;
					display: flex;
					flex-direction: row;
					justify-content: flex-start;
					align-items: flex-start;
					margin-bottom: 20px; } }
			&-text {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 18px;
				color: #000000;
				@include r(575) {
					text-align: left; } } }

		&-specifications {
			margin-bottom: 80px;
			&-title {
				font-family: Gilroy, sans-serif;
				font-weight: 600;
				font-size: 25px;
				color: #333333;
				margin-bottom: 20px; }

			&-slider {
				margin-bottom: 15px;
				&-wrapper {
					display: flex;
					flex-direction: column;
					justify-content: center;
					height: 100%; } }

			&-slide {
				overflow: hidden;
				position: relative; }

			&-img {
				width: 100%;
				height: 100%;
				display: block;
				object-fit: contain; }

			&-control {
				display: flex;
				justify-content: center;

				.card-specifications-slider__prev {
					margin-right: 10px; }

				.icon {
					path {
						fill: #989EAB;
						stroke: #989EAB; }
					circle {
						fill: #ffffff;
						stroke: #989EAB; }
					&:focus, &:hover {
						cursor: pointer;
						path {
							fill: #264ABA;
							stroke: #264ABA; }
						circle {
							fill: #ffffff;
							stroke: #264ABA; } } } } } } }


