.table {
	width: 100%;
	border: 1px solid #DADADA;
	tbody tr:nth-of-type(2n) {
		background-color: #F4F4F4; }
	th {
		font-size: 16px;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #616774;
		max-width: 280px;
		padding: 10px;
		vertical-align: top;
		border: 0;
		min-width: 280px;
		@include r(991) {
			min-width: 240px; }
		@include r(767) {
			min-width: 200px; } }
	td {
		font-size: 16px;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #333333;
		padding: 10px;
		vertical-align: top;
		border: 0;
		@include r(767) {
			white-space: nowrap;
			word-break: keep-all; } } }

