.project-detail {
	&-title {
		margin-bottom: 40px; }
	&-slider-nav {
		margin-left: 59px;
		margin-right: 56px;
		margin-bottom: 66px;
		.slick-track {
			margin-left: -165px; }
		.project-detail-slider-highlight {
			.project-detail-slide-img-wrapper {
				img {
					border: 4px solid #264aba; } } }
		.project-detail-slide {
			padding: 10px;
			&-img-wrapper {
				overflow: hidden;
				img {
					border: 4px solid transparent;
					width: 140px;
					height: 100%;
					object-fit: contain; } } } }

	&-slider-control {
		position: relative;
		@include r(767) {
			display: flex;
			flex-direction: row;
			justify-content: center;
			margin-bottom: 20px; }
		.project-detail-slide {
			overflow: hidden; }
		.icon {
			path {
				fill: #989EAB;
				stroke: #989EAB; }
			&:focus, &:hover {
				cursor: pointer;
				path {
					fill: #264ABA;
					stroke: #264ABA; } } }

		.project-detail-slider__prev {
			position: absolute;
			top: 50%;
			transform: translateY(-50%) translateX(-50%);
			left: 28px;
			@include r(767) {
				left: auto;
				transform: none;
				position: relative;
				margin-right: 30px; } }
		.project-detail-slider__next {
			position: absolute;
			top: 50%;
			transform: translateY(-50%) translateX(50%);
			right: 28px;
			@include r(767) {
				right: auto;
				transform: none;
				position: relative; } } }

	.project-detail-desc {
		font-family: Gilroy, sans-serif;
		font-weight: 500;
		font-size: 20px;
		color: #333333;
		margin-bottom: 20px; }

	&-slider-nav {
		@include r(767) {
			display: none; } }
	&-slider-for {
		img {
			@include r(575) {
				width: 100%;
				height: auto; } } } }

