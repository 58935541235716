.modal {
	z-index: 900000;
	background-color: rgba(#000000, .68);
	.close {
		position: absolute;
		top: 30px;
		right: 10px;
		font-size: 80px;
		line-height: 60px;
		font-weight: 300;
		color: #D1D4DA;
		@include r(767) {
			top: 10px;
			z-index: 40; }
		&:hover, &:focus, &:active {
			outline: none;
			box-shadow: none;
			border: 0; } }
	.bg-image {
		background-image: url("../img/manager.png");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: right bottom;
		background-size: contain;
		@include r(991) {
	 		background: none; } }
	&-content {
		border-radius: 0;
		border: 0;
		background: linear-gradient(180deg, rgba(255,255,255,0) 30px,  rgba(#F4F4F4,1) 30px, rgba(#F4F4F4,1) 100%);
		@include r(767) {
			background-color: rgba(#F4F4F4,1); } }
	&__form {
		padding-top: 80px;
		padding-left: 70px;
		margin-bottom: 75px;
		@include r(767) {
			padding-top: 40px;
			padding-left: 10px;
			padding-right: 10px;
			margin-bottom: 40px; }
		.custom-control-label {
			&:before {
				color: #A2AABC;
				background-color: transparent;
				border-color: #616774;
				box-shadow: none; } }
		.custom-control-input:checked~.custom-control-label::before {
			color: #A2AABC;
			border-color: #616774;
			background-color: transparent;
			box-shadow: none; }
		.custom-checkbox .custom-control-input:checked~.custom-control-label {
			&:after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23333333' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); } }

		.form-group {
			margin-bottom: 10px; }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 30px;
			color: #2A2B2D;
			margin-bottom: 10px;
			@include r(991) {
				line-height: 31px; }
			@include r(575) {
				font-size: 24px;
				line-height: 31px; } }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #616774;
			margin-bottom: 27px;
			max-width: 500px;
			@include r(575) {
				font-size: 14px;
				line-height: 16px; } }

		&-name, &-phone, &-email, &-message {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			background-color: #ffffff;
			color: #333333;
			font-size: 16px;
			box-shadow: none;
			border-radius: 3px;
			outline: none;
			height: 40px;
			border: 1px solid #ffffff;
			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #A2AABC;
				font-size: 16px; }
			&:focus, &:hover {
				border: 1px solid #A2AABC; } }
		&-message {
			resize: none;
			&.form-control {
				height: 140px; } }
		&-submit {
			@extend %button;
			width: 212px;
			margin: 0 0 22px auto;
			@include r(767) {
				margin: 0 auto 22px 0; } }
		&-rent-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #616774;
			padding-left: 20px;
			margin-bottom: 10px;
			@include r(575) {
				margin-bottom: 20px; }
			&:before, &:after {
				width: 25px;
				height: 25px;
				top: 0px;
				left: -25px; } }

		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #6A6A6A;
			margin-bottom: 0px;
			max-width: 400px;
			font-size: 12px;
			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #A2AABC;
				@extend %disable-text-decoration;
				font-size: 12px;
				&:hover, &:focus {
					color: #A2AABC;
					border-bottom: 1px solid #A2AABC; } } } }

	&-manager {
		width: 100px;
		@include r(1199) {
			display: none; }
		&-name {
			font-family: Gilroy, sans-serif;
			font-weight: 500;
			font-size: 20px;
			color: #264ABA; }
		&-position {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #616774; } } }

