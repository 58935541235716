.vertical {
	background-color: #D7D7D7;
	width: 1px;
	height: 45px;
	margin: 0;
	padding: 0;
	border: 0; }

.line {
	background-color: #F3F3F3;
	width: 100%;
	height: 1px;
	margin: 0;
	padding: 0;
	border: 0; }
