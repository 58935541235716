.map {
	position: relative;
	height: 640px;
	@include r(575) {
		height: auto; }
	&-info {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 65%;
		width: 420px;
		background-color: white;
		padding-top: 40px;
		padding-left: 70px;
		padding-bottom: 40px;
		z-index: 100;
		@extend %box-shadow;
		@include r(1200) {
			right: auto;
			left: 0;
			width: 420px;
			padding-top: 30px;
			padding-left: 50px;
			padding-bottom: 30px; }
		@include r(991) {
			left: 0;
			width: 320px;
			padding-top: 20px;
			padding-left: 20px;
			padding-bottom: 20px; }
		@include r(767) {
			display: none; }
		&-line {
			margin-bottom: 40px;
			&-title {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				padding-top: 0px;
				color: #989EAB;
				font-size: 14px;
				margin-bottom: 8px;
				.icon {
					margin-right: 10px;
					path {
						fill: #989EAB; } }
				@include r(575) {
					margin-top: 10px; } }
			&-content {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #333333;
				font-size: 16px;
				.phone {
					font-family: Gilroy, sans-serif;
					font-weight: 700;
					color: #333333;
					font-size: 22px;
					border-bottom: 1px dashed #333333;
					@extend %disable-text-decoration;
					&:focus, &:hover {
						color: #00568F;
						border-bottom: 1px solid #333333; } }
				.email {
					font-family: Gilroy, sans-serif;
					font-weight: 600;
					color: #264ABA;
					font-size: 20px;
					border-bottom: 1px solid #264ABA;
					@extend %disable-text-decoration;
					&:focus, &:hover {
						color: #264ABA;
						border-bottom: 1px dashed #264ABA; } } } } } }
