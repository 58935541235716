.footer {
	background-color: #181C27;
	.line {
		background-color: rgba(#616774, .21);
		height: 1px;
		width: 100%;
		margin: 0;
		padding: 0; }
	&__upper {
		padding-top: 40px;
		padding-bottom: 25px; }
	&__middle {
		padding-top: 40px; }
	&__lower {
		padding-top: 40px;
		padding-bottom: 40px;
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		display: flex;
		justify-content: space-between;
		align-items: center;
		@include r(575) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start; } }
	.col {
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			padding-top: 0px;
			color: #616774;
			font-size: 14px;
			margin-bottom: 8px;
			.icon {
				margin-right: 10px; }
			@include r(575) {
				margin-top: 10px; } }
		&-content {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #ffffff;
			font-size: 16px;
			margin-bottom: 10px;
			.phone {
				font-family: Gilroy, sans-serif;
				font-weight: 700;
				color: #ffffff;
				font-size: 22px;
				border-bottom: 1px dashed #616774;
				@extend %disable-text-decoration;
				@include r(992) {
					font-size: 18px; }
				&:focus, &:hover {
					color: #00568F;
					border-bottom: 1px solid #00568F; } }
			.email {
				font-family: Gilroy, sans-serif;
				font-weight: 600;
				color: #00568F;
				font-size: 20px;
				border-bottom: 1px solid #00568F;
				@extend %disable-text-decoration;
				@include r(992) {
					font-size: 18px; }
				&:focus, &:hover {
					color: #ffffff;
					border-bottom: 1px dashed #616774; } }


			@include r(575) {
				margin-bottom: 20px; } } }
	.list-unstyled {
		margin-bottom: 40px;
		.list-unstyled--link {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			color: #ffffff;
			font-size: 18px;
			text-decoration: none;
			&:focus, &:hover {
				color: #00568F; } } }
	.list-unstyled .list-unstyled {
		margin-top: 10px;
		.list-unstyled--link {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #616774;
			font-size: 16px;
			@extend %disable-text-decoration;
			&:focus, &:hover {
				color: #00568F; } } }
	.list-unstyled--item {
		margin-bottom: 15px; }

	.list-dashed li:before {
		content: "—";
		color: #616774;
		position: relative;
		left: -5px; }
	&__form {
		.form-group {
			margin-bottom: 0; }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			color: #ffffff;
			font-size: 18px;
			margin-bottom: 10px; }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #616774;
			font-size: 14px;
			margin-bottom: 25px; }
		&-email {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			background-color: #272B34;
			color: #ffffff;
			font-size: 16px;
			box-shadow: none;
			border-radius: 3px;
			margin-bottom: 12px;
			outline: none;
			border-color: transparent;
			height: 40px;
			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				color: #616774;
				font-size: 16px; }
			&:active {
				background-color: #272B34;
				color: #ffffff; } }
		&-submit {
			font-family: Gilroy, sans-serif;
			font-weight: 500;
			background-color: #264ABA;
			color: #ffffff;
			font-size: 16px;
			box-shadow: none;
			border-radius: 0;
			outline: none;
			border-color: transparent;
			margin-bottom: 14px;
			height: 40px; }
		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 12px;
			color: #616774;
			margin-bottom: 21px;
			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 12px;
				color: #A2AABC;
				@extend %disable-text-decoration;
				&:hover, &:focus {
					color: #A2AABC;
					border-bottom: 1px solid #A2AABC; } } }
		.custom-control-label {
			&:before {
				color: #A2AABC;
				background-color: transparent;
				border-color: #616774;
				box-shadow: none; } }
		.custom-checkbox .custom-control-input:checked~.custom-control-label {
			&:after {
				background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23A2AABC' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e"); } }

		.custom-control-input:checked~.custom-control-label::before {
			color: #A2AABC;
			border-color: #616774;
			background-color: transparent;
			box-shadow: none; } }

	.about {
		color: #616774;
		font-size: 14px;
		@include r(575) {
			margin-bottom: 10px; } }
	.privacy-policy {
		text-align: center;
		align-self: flex-end;
		max-width: 240px;
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			color: #264ABA;
			font-size: 14px;
			@extend %disable-text-decoration;
			&:hover, &:focus {
				border-bottom: 1px solid #264ABA; } }
		@include r(767) {
			text-align: left; }
		@include r(575) {
			align-self: flex-start;
			margin-bottom: 10px; } }
	.creator {
		align-self: flex-end;
		text-align: right;
		@include r(575) {
			align-self: flex-start;
			margin-bottom: 10px;
			text-align: left; } }
	.creator-link {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #6B717D;
		font-size: 14px;
		@extend %disable-text-decoration;
		.icon {
			margin-right: 9px;
			path {
				fill: #ffffff; } }
		&:hover, &:focus {
			color: #6B717D;
			.creator-link-text {
				border-bottom: 1px solid #6B717D; } } } }
