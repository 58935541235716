.thanks {
	&-wrapper {
		padding-top: 130px;
		padding-bottom: 215px;
		@include r(575) {
			padding-top: 20px;
			padding-bottom: 15px; }
		@include r(767) {
			padding-top: 30px;
			padding-bottom: 45px; } }
	&-title {
		font-family: Gilroy, sans-serif;
		font-weight: 700;
		font-size: 30px;
		color: #333333;
		margin-bottom: 15px; }
	&-desc {
		font-family: Gilroy, sans-serif;
		font-weight: 500;
		font-size: 18px;
		color: #616774;
		margin-bottom: 30px; }
	&-info {
		font-family: Gilroy, sans-serif;
		font-weight: 500;
		font-size: 18px;
		color: #616774;
		margin-bottom: 10px; }
	&-phone {
		.icon {
			margin-right: 15px;
			path {
				fill: #264ABA; } }
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 24px;
			color: #333333;
			@extend %disable-text-decoration;
			span {
				border-bottom: 1px solid transparent; }
			&:hover, &:focus {
				span {
					border-bottom: 1px solid #333333; } } } }
	.bg-image {
		padding-top: 45px;
		background-image: url("../img/manager.png");
		background-repeat: no-repeat;
		background-attachment: scroll;
		background-position: right bottom;
		background-size: contain;
		@include r(575) {
			background: none; }
		@include r(767) {
			padding-top: 0; } } }
