.navigation {
	box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.09);
	position: relative;
	&__upper {
		padding-top: 15px;
		padding-bottom: 20px;
		&-modal_button {
			margin-left: auto;
			width: 100%;
			height: auto;
			@extend %button;
			.transfer {
				display: none; } } }

	&__lower {
		@include r(767) {
			display: none; } }

	.navigation-col-title {
		font-family: Gilroy, sans-serif;
		font-weight: 400;
		color: #616161;
		font-size: 12px;
		@include r(991) {
			margin-right: 10px; } }
	.navigation-col-email {
		max-width: 170px;
		text-align: right;
		align-items: flex-start;
		@include r(991) {
			max-width: 100%;
			justify-content: flex-start;
			align-items: center; }
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 18px;
			color: #264ABA;
			@extend %disable-text-decoration;
			border-bottom: 1px solid rgba(#00568F, .2);
			transform-origin: center;
			@include r(991) {
				font-size: 16px; }
			&:focus, &:hover {
				color: #264ABA;
				border-bottom: 1px dashed rgba(#00568F, .2); } } }

	.navigation-col-phone {
		max-width: 180px;
		text-align: left;
		@include r(991) {
			max-width: 100%;
			justify-content: flex-start;
			align-items: center; }
		&-link {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 20px;
			color: #333333;
			@extend %disable-text-decoration;
			border-bottom: 0px solid #333333;
			@include r(991) {
				font-size: 18px; }
			&:focus, &:hover {
				color: #333333;
				border-bottom: 1px solid #333333; }
			&-mobile {
				margin-right: 20px;
				display: inline-block;
				@extend %disable-text-decoration;
				.icon {
					height: 15px;
					width: 15px;
					path {
						fill: #333333; } }
				&:hover, &:focus {
					.icon {
						path {
							fill: #264ABA; } } } } } }


	.menu-list {
		display: flex;
		justify-content: space-between;
		width: 100%;
		margin-bottom: 0;
		.menu-list {
			display: none; } }

	.menu-link {
		display: block;
		font-family: Gilroy, sans-serif;
		font-weight: 600;
		font-size: 16px;
		color: #333333;
		padding: 20px 0px;
		text-transform: uppercase;
		@extend %disable-text-decoration;
		position: relative;
		@include r(991) {
			font-size: 15px; }
		&:after {
			content: "";
			display: block;
			position: absolute;
			left: 0;
			bottom: 0;
			width: 100%;
			height: 2px;
			transform-origin: center;
			transform: scaleX(0);
			transition: transform .2s ease-in-out;
			background-color: #FE1721; }
		&:hover, &:focus {
			color: #333333;
			&:after {
				transform: scaleX(1); } } }
	.menu-item {
		padding: 0;
		&.active {
			.menu-link {
				color: #333333;
				&:after {
					transform: scaleX(1); } } } }

	.vertical {
		margin: 0 30px;
		@include r(991) {
			display: none; } }


	.line {
		margin: 0;
		@include r(575) {
			display: none; } }


	.mobile-col-menu {
		display: none;
		@include r(767) {
			display: flex;
			align-items: center;
			justify-content: flex-end; } }
	.desktop-col-menu {
		display: block;
		@include r(767) {
			display: none; } } }
