.subscribe {
	padding-top: 40px;
	padding-bottom: 80px;
	position: relative;
	@include r(767) {
		padding-top: 20px;
		padding-bottom: 20px; }

	@include r(575) {
		padding-top: 10px;
		padding-bottom: 20px; }
	.subscribe__form {
		@include r(575) {
			margin: 0 10px; }
		&-wrapper {
			padding-top: 40px;
			padding-bottom: 50px;
			background-color: #F3F3F3;
			@include r(991) {
				padding-bottom: 30px; }
			@include r(767) {
				padding-top: 20px;
				padding-bottom: 20px; } }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			color: #333333;
			font-size: 25px;
			margin-bottom: 30px;
			text-align: center;
			@include r(575) {
				font-size: 19px;
				text-align: justify; } }

		&-email {
			background-color: #ffffff;
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #333333;
			margin-bottom: 15px;
			border-radius: 3px;
			border: 1px solid #ffffff;
			box-shadow: none;
			outline: 0;
			width: 100%;
			height: 40px;
			&::placeholder {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 16px;
				color: #A2AABC; }
			&:hover, &:focus, &:active {
				border: 1px solid #DADADA; } }

		&-submit {
			width: 180px;
			height: 40px;
			@extend %button;
			margin-bottom: 15px;
			@include r(575) {
				width: 100%;
				height: auto; } }

		&-terms-label {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 12px;
			color: #333333;
			&-link {
				font-family: Gilroy, sans-serif;
				font-weight: 400;
				font-size: 12px;
				color: #A2AABC;
				@extend %disable-text-decoration;
				border-bottom: 1px solid #ffffff;
				&:hover, &:focus {
					color: #A2AABC;
					border-bottom: 1px solid #A2AABC; } } } }
	&-thanks {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.1s ease-in-out;
		text-align: center;
		background-color: #F3F3F3;
		padding-top: 45px;
		padding-left: 100px;
		padding-right: 100px;
		@include r(767) {
			padding-top: 60px; }
		@include r(575) {
			padding-top: 20px;
			padding-left: 40px;
			padding-right: 40px; }
		&.active {
			opacity: 1; }
		&-title {
			font-family: Gilroy, sans-serif;
			font-weight: 600;
			font-size: 25px;
			color: #333333;
			margin-bottom: 10px; }
		&-desc {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 16px;
			color: #616774;
			margin-bottom: 30px;
			@include r(575) {
				margin-bottom: 10px; } }
		.icon {
			path {
				fill: #264ABA; } } } }

