.completed_projects {
	padding-top: 10px;
	padding-bottom: 80px;
	&-title {
		margin-bottom: 40px; }
	.completed_project {

		width: 100%;
		display: flex;
		&-wrapper {
			margin-bottom: 30px;
			@extend %box-shadow; }
		@include r(767) {
			flex-direction: column; }
		&-img {
			background-color: #F3F3F3;
			display: block;
			max-width: 392px;
			width: 100%;
			height: 100%;
			object-fit: contain;
			@include r(767) {
				max-width: 100%; } }
		&-info {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			width: 100%; }
		&-desc {
			background-color: #F3F3F3;
			padding-top: 30px;
			padding-left: 30px;
			padding-bottom: 20px;
			height: 100%; }
		&-place {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 14px;
			color: #616774;
			margin-bottom: 4px; }
		&-name {
			font-family: Gilroy, sans-serif;
			font-weight: 700;
			font-size: 30px;
			color: #333333;
			margin-bottom: 4px;
			@include r(991) {
				font-size: 28px; } }

		&-work {
			font-family: Gilroy, sans-serif;
			font-weight: 400;
			font-size: 18px;
			color: #616774;
			margin-bottom: 4px; }
		&-details {
			display: flex;
			padding: 15px 30px;
			background-color: #ffffff;
			width: 100%;
			justify-content: space-between;
			align-items: center;
			@include r(991) {
				display: none; }
			&-mobile {
				display: none;
				background-color: #ffffff;
				width: 100%;
				justify-content: space-between;
				align-items: center;
				padding: 15px 30px;
				@include r(991) {
					display: flex; }
				@include r(767) {
					flex-direction: column; }
				&-container {
					width: 100%;
					background-color: #ffffff;
					justify-content: space-between;
					align-items: center;
					display: flex;
					@include r(767) {
						margin-bottom: 10px; }
					@include r(575) {
						flex-direction: column; } }
				.completed_project-link {
					@include r(767) {
						margin: 0 auto; }
					@include r(575) {
						width: 100%; } }
				.vertical {
					@include r(575) {
						background-color: #F3F3F3;
						width: 100%;
						height: 1px;
						margin: 10px 0;
						padding: 0;
						border: 0; } } }
			&-col {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: space-between;
				height: 100%;
				flex-grow: 1;
				@include r(767) {
					width: 33%; }
				@include r(575) {
					flex-direction: row;
					justify-content: flex-start;
					align-items: flex-start;
					text-align: left;
					width: 100%;
					margin-bottom: 10px; }
				&-title {
					font-family: Gilroy, sans-serif;
					font-weight: 400;
					font-size: 16px;
					color: #616774;
					margin-bottom: 8px;
					@include r(575) {
						margin-right: 10px; } }
				&-desc {
					font-family: Gilroy, sans-serif;
					font-weight: 600;
					font-size: 20px;
					color: #333333; } } }
		&-link {
			@extend %button;
			width: 210px;
			margin-right: auto;
			@include r(1199) {
				width: auto; }
			@include r(991) {
				width: 210px; }
			&-mobile {
				display: none;
				@extend %button;
				@include r(991) {
					margin: 0 auto;
					display: block; } } } }


	&-link {
		@extend %button;
		width: 224px;
		margin: 50px auto 0; } }

